<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      >
        <v-btn
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-lock</v-icon>Unlock
        </v-btn>
      </slot>
    </template>

    <v-card>
      <v-card-text>
        <p class="text-h6 text--primary">
          {{ item.title }}
        </p>
        <div class="text--primary">
          <span>Bạn {{ remain >= 0 ? 'sẽ xử dụng' : 'cần' }} </span>
          <v-icon
            size="18"
            color="warning"
          >
            mdi-alpha-s-circle-outline
          </v-icon>
          <span class="primary--text">{{ item.price }}</span>
          <span> để mở khóa bài viết này. </span>

          <span>{{ remain >= 0 ? 'Còn lại' : 'Hiện có' }} </span>
          <v-icon
            size="18"
            color="warning"
          >
            mdi-alpha-s-circle-outline
          </v-icon>
          <span class="primary--text">{{ remain >= 0 ? remain : fund }}.</span>
        </div>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          v-if="remain >= 0"
          color="primary"
          text
          @click="confirm"
        >
          <v-progress-circular
            v-if="loading"
            :size="14"
            :width="2"
            indeterminate
          />
          <span v-else>Xác nhận</span>
        </v-btn>
        <v-btn
          v-else
          color="primary"
          text
          @click="showQR"
        >
          Nạp tài khoản
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { EventBus } from '@/event-bus'

export default {

  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    loading: false,
  }),

  computed: {
    remain() {
      return this.fund - this.item.price
    },
    fund() {
      const user = this.$store.getters['user/user'] || {}

      return user.fund || 0
    },
  },

  methods: {
    showQR() {
      EventBus.$emit('show-qr')
      this.close()
    },
    confirm() {
      this.loading = true
      this.$httpUser.put(`/api/articles/${this.item.id}/owners`)
        .then(resp => {
          this.$store.commit('user/CHARGE', this.item.price)
          this.$emit('unlocked', this.index, resp.data.data)
          EventBus.$emit('show-message', 'Mở khóa thành công')
        })
        .catch(err => {
          EventBus.$emit('show-error', err.response.data.message)
        })
        .finally(() => {
          this.close()
        })
    },
    close() {
      this.dialog = false
      this.loading = false
    },
  },
}
</script>
